import React from 'react';
import { FlexContainer } from '../shared/Container';
import { MouseParallaxChild, MouseParallaxContainer } from 'react-parallax-mouse';
import chest from '../../assets/images/treasury/chest.png';
import bannerBackground from '../../assets/images/backgrounds/background-stake.png';
import styled from 'styled-components';

import coinMain from '../../assets/images/treasury/coin-main.png';
import coinDown from '../../assets/images/treasury/coin-down.png';
import coinDark from '../../assets/images/treasury/coin-dark.png';
import coinFlipped from '../../assets/images/treasury/coin-flipped.png';
import coinFront from '../../assets/images/treasury/coin-front.png';
import coinUnderStake from '../../assets/images/treasury/coin-under-stake.png';
import coinStake from '../../assets/images/treasury/coin-stake.png';
import Label from '../shared/Label';
import useWindowSize from '../../hooks/useWindowSize';
import theme from '../../styles/theme';
import TreasuryDataList from './TreasuryDataList';

const BackgroundContainer = styled(FlexContainer)`
  /* margin-top: 64px; */
  margin-bottom: -20px;
  height: 100%;
  flex-direction: column;

  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.desktopPixel - 1}px`}) {
    margin-bottom: -40px;
  }
`;
const BackgroundContainer2 = styled(FlexContainer)`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 120px 0px;
  flex-direction: column;
  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.desktopPixel - 1}px`}) {
    flex-direction: column;
    background-size: contain;
    background-position: center;
    padding: 120px 24px 120px;
  }
`;

const ContentWrapper = styled(FlexContainer)`
  position: relative;

  min-height: 120vh;

  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.desktopPixel}px`}) {
    flex-direction: column;
  }
  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel}px`}) {
    flex-direction: column;
    min-height: 55vh;
  }
`;

const ChestBackground = styled(FlexContainer)`
  background-image: ${`url(${chest})`};
  mix-blend-mode: overlay;
  opacity: 0.8;
  position: absolute;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 120vh;

  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.desktopPixel}px`}) {
    background-position-x: 20%;
    background-size: initial;
  }
  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel}px`}) and (max-width: ${({ theme: { mediaQueries } }) =>
      `${mediaQueries.desktopPixel - 1}px`}) {
    background-position-x: 20%;
    background-size: ${({ width }) => width * 2}px;
    min-height: 70vh;
  }
  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel - 1}px`}) {
    background-position-x: 20%;
    background-size: ${({ width }) => width * 2}px;
    min-height: 55vh;
  }
`;

const Banner = styled(FlexContainer)`
  background-image: ${`url(${bannerBackground})`};
  flex-wrap: wrap;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  opacity: 1;
  margin: 0px 174px;
  padding: 76px;
  border-radius: 32px;
  position: relative;
  z-index: 1;
  gap: 24px;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.desktopPixel}px`}) {
    flex-direction: column;
    margin: 0px 16px;
    padding: 24px;
    margin-bottom: 28px;
    background-size: cover;
  }

  .last-child {
    white-space: nowrap;
    @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.desktopPixel - 1}px`}) {
      white-space: normal;
      text-align: center;
    }
  }
`;

const TOKEN_UNDER_STAKE = {
  top: '5%',
  left: '20%',
  imgUrl: coinUnderStake,
};

const Treasury = () => {
  const [width, height] = useWindowSize();

  const getProportionalCalculation = (top, left) => {
    const widthContainer = width / 2;
    const mobileHeightOffset = (height / 2) * 0.2;
    const tabletHeightOffset = (height / 2) * 0.15;

    if (width < theme.mediaQueries.mobilePixel) {
      return {
        top: height - height * top + mobileHeightOffset,
        left: widthContainer - widthContainer * left,
      };
    } else if (width >= theme.mediaQueries.mobilePixel && width <= theme.mediaQueries.desktopPixel) {
      return {
        top: height - height * top + tabletHeightOffset,
        left: widthContainer - widthContainer * left,
      };
    } else {
      return {
        top: height - height * top,
        left: widthContainer - widthContainer * left,
      };
    }
  };
  const TOKENS = [
    {
      factorX: 0.02,
      factorY: 0.02,
      top: 0.75,
      left: 0.4,
      mobileTop: 0.97,
      mobileLeft: 0.4,
      imgUrl: coinMain,
      zIndex: 100,
    },
    {
      factorX: -0.03,
      factorY: -0.03,
      top: 0.8,
      left: 0.5,
      mobileTop: 1.0,
      mobileLeft: 0.6,
      imgUrl: coinDown,
    },
    {
      factorX: -0.02,
      factorY: -0.02,
      top: 0.85,
      left: 0.2,
      mobileTop: 1.02,
      mobileLeft: 0.05,
      imgUrl: coinDark,
    },
    {
      factorX: 0.02,
      factorY: 0.02,
      top: 1,
      left: 0.2,
      mobileTop: 1.1,
      mobileLeft: 0.000005,
      imgUrl: coinFlipped,
    },
    {
      factorX: -0.04,
      factorY: -0.04,
      top: 1.1,
      left: 0.7,
      mobileTop: 1.15,
      mobileLeft: 1,
      imgUrl: coinFront,
      zIndex: 1,
    },
  ];

  return (
    <>
      <BackgroundContainer>
        <BackgroundContainer2 id="treasury">
          <div
            style={{
              position: 'absolute',
              ...TOKEN_UNDER_STAKE,
            }}
          >
            <img src={TOKEN_UNDER_STAKE.imgUrl} style={{ width: width <= theme.mediaQueries.mobilePixel ? '60%' : '100%' }} alt="token" />
          </div>
          <Banner
            className="pointer"
            onClick={() => {
              window.open('https://swap.ecko.finance/stake', '_blank', 'noopener,noreferrer');
            }}
          >
            <Label className="last-child" size="big" fontFamily="syncopate">
              STAKE
            </Label>
            <img style={{ zIndex: 1 }} src={coinStake} alt="token" />
            <Label className="last-child" size="big" fontFamily="syncopate-regular">
              TO ACCRUE VOTING POWER
            </Label>
          </Banner>
          <ContentWrapper width={width}>
            <ChestBackground id="chest" width={width} />
            <MouseParallaxContainer
              className="parallax w-100"
              containerStyle={{
                position: 'relative',
                marginBottom: 34,
                zIndex: 1,
                minHeight: width < theme.mediaQueries.mobilePixel ? '55vh' : width <= theme.mediaQueries.desktopPixel ? '70vh' : 500,
                overflow: 'unset',
                width: width <= theme.mediaQueries.desktopPixel ? '100%' : '50%',
              }}
            >
              {TOKENS.map((token, index) => (
                <MouseParallaxChild
                  key={index}
                  factorX={token.factorX}
                  factorY={token.factorY}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top:
                      width <= theme.mediaQueries.mobilePixel && token.mobileTop && token.mobileLeft
                        ? getProportionalCalculation(token.mobileTop, token.mobileLeft).top
                        : getProportionalCalculation(token.top, token.left).top,
                    left:
                      width <= theme.mediaQueries.mobilePixel && token.mobileTop && token.mobileLeft
                        ? getProportionalCalculation(token.mobileTop, token.mobileLeft).left
                        : getProportionalCalculation(token.top, token.left).left,
                    zIndex: token.zIndex,
                  }}
                >
                  <img style={{ width: width <= theme.mediaQueries.mobilePixel ? '60%' : '100%' }} src={token.imgUrl} alt="token" />
                </MouseParallaxChild>
              ))}
            </MouseParallaxContainer>
            {width > theme.mediaQueries.desktopPixel && (
              <FlexContainer style={{ width: '50%', minHeight: 500, alignSelf: 'center' }} className="h-100 justify-ce align-ce ">
                <TreasuryDataList />
              </FlexContainer>
            )}
          </ContentWrapper>
          {width <= theme.mediaQueries.desktopPixel && (
            <FlexContainer
              style={{ width: '100%', minHeight: 500, alignSelf: 'center' }}
              mobileStyle={{ zIndex: 100, marginTop: -40 }}
              className="h-100 justify-ce align-ce "
            >
              <TreasuryDataList />
            </FlexContainer>
          )}
        </BackgroundContainer2>
      </BackgroundContainer>
    </>
  );
};

export default Treasury;
