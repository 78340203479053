import axios from 'axios';

export const getAnalyticsData = async (dateStart, dateEnd) => {
  const url = `analytics/get-data?dateStart=${dateStart}&dateEnd=${dateEnd}`;
  return axios.get(`https://api.ecko.finance/${url}`, {
    headers: { accept: 'application/json' },
  });
};

export const getKaddexCoingecko = async () => {
  return axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=kaddex&vs_currencies=USD`, {
    headers: { accept: 'application/json' },
  });
};
