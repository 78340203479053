import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useWindowSize from '../../hooks/useWindowSize';
import theme from '../../styles/theme';
import Accordion from './Accordion';
import { FlexContainer } from './Container';
import Label from './Label';

const LHTemplateWrapper = styled(FlexContainer)`
  transition: all 0.5s ease;
  display: flex;

  .sticky {
    position: fixed;
    top: 120px;
    left: 174px;
    width: 100%;
  }
`;

const HtmlFormatterContainer = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-size: 14px;
  margin-top: 20px;
`;

const LHTemplate = ({ title, description, items }) => {
  const [width] = useWindowSize();
  const containerRef = useRef(null);
  const fixedContainerRef = useRef(null);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleIsSticky);
    return () => {
      window.removeEventListener('scroll', handleIsSticky);
    };
    // eslint-disable-next-line
  }, [window.scrollY]);

  const handleIsSticky = () => {
    containerRef.current.offsetTop <= window.scrollY && containerRef.current.offsetTop + containerRef.current.offsetHeight - 160 > window.scrollY
      ? setIsSticky(true)
      : setIsSticky(false);
  };

  return (
    <LHTemplateWrapper
      // className="justify-sb"
      style={{ marginBottom: 160 }}
      tabletClassName="column"
      mobileClassName="column"
      reference={containerRef}
      id={title}
      offset={width <= theme.mediaQueries.desktopPixel ? 64 : 32}
      desktopStyle={{ padding: '0 174px' }}
      tabletStyle={{ padding: '0 174px' }}
      mobileStyle={{ padding: '0 50px' }}
    >
      <FlexContainer style={{ minWidth: isSticky && 'calc(40% - 12px)' }}></FlexContainer>
      <FlexContainer
        reference={fixedContainerRef}
        desktopStyle={{ width: isSticky ? `${fixedContainerRef.current.clientWidth}px` : '40%' }}
        className={'column'}
        desktopClassName={isSticky && 'sticky'}
        tabletStyle={{ marginBottom: 64 }}
        mobileStyle={{ marginBottom: 64 }}
      >
        <Label fontFamily="syncopate" size="large" style={{ marginBottom: 32 }}>
          {title}
        </Label>
        <Label fontFamily="basier" size="small" style={{ maxWidth: 440 }}>
          {description}
        </Label>
      </FlexContainer>
      <FlexContainer
        className="column"
        gap={48}
        desktopStyle={{
          justifySelf: 'end',
          width: '60%',
          overflow: 'hidden',
          marginLeft: width <= theme.mediaQueries.desktopPixel ? 64 : 32,
          zIndex: '1',
        }}
      >
        {items.map((item, i) => (
          <Accordion
            key={i}
            header={
              <FlexContainer
                style={{ maxWidth: width - 150, flexDirection: width <= theme.mediaQueries.mobilePixel ? 'column' : 'row', paddingRight: 46 }}
              >
                <Label fontFamily="syncopate" size="large">
                  {item.title}
                </Label>
              </FlexContainer>
            }
            content={<HtmlFormatterContainer dangerouslySetInnerHTML={{ __html: item.description }}></HtmlFormatterContainer>}
          />
        ))}
      </FlexContainer>
    </LHTemplateWrapper>
  );
};

export default LHTemplate;
