import React from 'react';
import styled from 'styled-components';
import { RoadmapFlagcon } from '../../../assets';
import { getColor } from '../../../styles/theme';
import { FlexContainer } from '../../shared/Container';
import Label from '../../shared/Label';

const Line = styled(FlexContainer)`
  position: absolute;
  border-left: ${({ theme: { colors } }) => `3px solid ${colors.lightGrey}`};
  top: 0;
  bottom: 0;
  left: 35px;
  ::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 8%;
    background: ${({ color }) => getColor(color)};
    top: 0;
    bottom: 0;
    left: -3px;
  }
`;

const MobileRoadmap2023 = ({ color }) => {
  return (
    <FlexContainer className="column justify-sb relative w-100" style={{ padding: '20px 0' }}>
      <Line color={color} />
      {Object.values(CONFIGURATION)
        .sort((a, b) => a.position - b.position)
        .map((value, i) => {
          return (
            <FlexContainer
              key={i}
              style={{ paddingLeft: 28.5, zIndex: 2, marginTop: value.isMain && 20 }}
              onClick={() => {
                if (value.href) {
                  window.open(value.href, '_blank');
                }
              }}
            >
              <div
                style={{
                  position: 'relative',
                  height: 16,
                  width: 16,
                  background: getColor(value?.isMain ? 'pink' : value.isCompleted ? color : 'light-grey'),
                  borderRadius: '50%',
                }}
              >
                {value?.isMain && <RoadmapFlagcon style={{ position: 'absolute', width: 40, left: 3, bottom: 8, zIndex: 4 }} />}
              </div>

              <FlexContainer className="column" gap={8} key={i} style={{ marginLeft: 10 }}>
                {value?.text?.map((tspan, j) => (
                  <Label key={j} fontSize={tspan.fontSize || 15} color={value?.isMain ? 'pink' : value.isCompleted ? color : 'light-grey'}>
                    {tspan.text}
                  </Label>
                ))}
              </FlexContainer>
            </FlexContainer>
          );
        })}
    </FlexContainer>
  );
};

export default MobileRoadmap2023;

const CONFIGURATION = {
  DAO_WEBSITE_RELEASE: {
    position: 0,
    text: [{ text: 'eckoDAO website release' }],
    isCompleted: true,
    isMain: true,
  },
  DISCORD: {
    position: 1,
    text: [{ text: 'Discord refactoring' }],
    isCompleted: false,
  },
  LITEPAPER: {
    position: 2,
    text: [{ text: 'Litepaper Governance Framework' }],
    isCompleted: false,
  },
  AMBASSADOR_PROGRAM: {
    position: 3,
    text: [{ text: 'Ambassador Program' }],
    isCompleted: false,
  },
  VOTING_POWER: {
    position: 4,
    text: [{ text: 'Voting Power' }, { text: 'to Liquidity Providers', fontSize: 10 }],
    isCompleted: false,
  },
  SKDX_LIQUIDITY_PROVIDER_TOKEN: {
    position: 5,
    text: [{ text: '$KDX Liquidity Provider Token' }],
    isCompleted: false,
  },
  WORKSHOP: {
    position: 6,
    text: [{ text: 'Workshop tool Live' }],
    isCompleted: false,
  },
  SMART_CONTRACT_AUDIT: {
    position: 7,
    text: [{ text: 'Smart Contract Audit' }],
    isCompleted: false,
  },
  DAO_PORTAL_BETA: {
    position: 8,
    text: [{ text: 'eckoDAO portal BETA Live' }],
    isCompleted: false,
  },
  FIRST_PROPOSAL: {
    position: 9,
    text: [{ text: 'First Proposal Cycle' }],
    isCompleted: false,
  },
  DAO_PORTAL_V1_LIVE: {
    position: 10,
    text: [{ text: 'eckoDAO portal V1 Live' }],
    isCompleted: false,
  },
  KADENA_DAO_GENERATOR: {
    position: 11,
    text: [{ text: 'Kadena DAO Generator Live' }],
    isCompleted: false,
  },
};
