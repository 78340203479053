import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import { getAnalyticsData, getKaddexCoingecko } from '../../api/analytics';
import { FlexContainer } from '../shared/Container';
import CornerBorderContainer from '../shared/CornerBorderContainer';
import Label from '../shared/Label';

const TreasuryDivider = styled(Divider)`
  height: 1px;
  background: #ffffff32;
  width: 100%;
  align-self: center;
`;

const TreasuryDataList = () => {
  const [kdxUsdPrice, setKdxUsdPrice] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);

  useEffect(() => {
    Promise.allSettled([getKaddexCoingecko(), getAnalyticsData(moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))])
      .then((result) => {
        const kdxPriceRes = result[0];
        const analyticsRes = result[1];
        if (kdxPriceRes.status === 'fulfilled') {
          setKdxUsdPrice(kdxPriceRes.value.data.kaddex.usd);
        }
        if (analyticsRes.status === 'fulfilled') {
          setAnalyticsData(analyticsRes.value.data[analyticsRes.value.data.length - 1]);
        }
      })
      .catch((error) => console.log('errorr'));
  }, []);

  return (
    <CornerBorderContainer gap={32} bgColor={'#432c3b32'}>
      <FlexContainer className="column" gap={32}>
        {analyticsData?.daoTreasury?.amount ? (
          <>
            <FlexContainer className="column">
              <Label fontFamily="syncopate" size="big">
                {analyticsData.daoTreasury.amount.toLocaleString('en-US', { maximumFractionDigits: 2 })} KDX
              </Label>
              {kdxUsdPrice && (
                <Label fontFamily="syncopate-regular" size="large" style={{ opacity: 0.4 }}>
                  {(analyticsData.daoTreasury.amount * kdxUsdPrice).toLocaleString('en-US', { maximumFractionDigits: 2 })} USD
                </Label>
              )}
              <Label fontFamily="syncopate-regular" size="medium">
                DAO TREASURY
              </Label>
            </FlexContainer>
            <TreasuryDivider />
          </>
        ) : (
          ''
        )}

        <>
          <FlexContainer className="column">
            <Label fontFamily="syncopate" size="big">
              2,500,000 KDX
            </Label>
            {kdxUsdPrice && (
              <Label fontFamily="syncopate-regular" size="large" style={{ opacity: 0.4 }}>
                {(2500000 * kdxUsdPrice).toLocaleString('en-US', { maximumFractionDigits: 2 })} USD
              </Label>
            )}
            <Label fontFamily="syncopate-regular" size="medium">
              DAO TREASURY INVESTED
            </Label>
          </FlexContainer>
          <TreasuryDivider />
        </>

        {analyticsData?.circulatingSupply?.stakedAmount ? (
          <>
            <FlexContainer className="column">
              <Label fontFamily="syncopate" size="big">
                {analyticsData.circulatingSupply.stakedAmount.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                <Label
                  fontFamily="syncopate"
                  size="medium"
                  style={{ alignSelf: 'self-end', marginLeft: 8 }}
                  desktopStyle={{ marginBottom: 2, marginLeft: 16 }}
                >
                  s
                </Label>
                KDX
              </Label>
              {kdxUsdPrice && (
                <Label fontFamily="syncopate-regular" size="large" style={{ opacity: 0.4 }}>
                  {(analyticsData.circulatingSupply.stakedAmount * kdxUsdPrice).toLocaleString('en-US', { maximumFractionDigits: 2 })} USD
                </Label>
              )}
              <Label fontFamily="syncopate-regular" size="medium">
                $KDX Governance Mining
              </Label>
            </FlexContainer>
            <TreasuryDivider />
          </>
        ) : (
          ''
        )}

        {/* //TODO */}
        <>
          <FlexContainer className="column">
            <Label fontFamily="syncopate" size="big">
              86.65 VP
            </Label>
            <Label fontFamily="syncopate-regular" size="medium">
              AVERAGE VOTING POWER
            </Label>
          </FlexContainer>
        </>
      </FlexContainer>
    </CornerBorderContainer>
  );
};

export default TreasuryDataList;
