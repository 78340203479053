import React from 'react';
import { EckoDAOLogo } from '../assets';
import Community from '../components/community/Community';
import Features from '../components/features/Features';
import Roadmap from '../components/roadmap/Roadmap';
import Hero from '../components/sections/Hero';
import { FlexContainer } from '../components/shared/Container';
import Treasury from '../components/treasury/Treasury';
import coinImage from '../assets/images/first-section/coin-first-section.png';
import GradientContainer from '../components/shared/GradientContainer';
import Label from '../components/shared/Label';
import useWindowSize from '../hooks/useWindowSize';

const MainContainer = () => {
  const [width] = useWindowSize();

  return (
    <FlexContainer className="column">
      <Hero
        title={<EckoDAOLogo style={{ height: width <= 780 && '36px', width: width <= 780 && '198px' }} />}
        subTitle={
          <Label
            fontFamily="syncopate-regular"
            size="big"
            className="justify-ce wrap"
            style={{ letterSpacing: '0', textAlign: 'start', whiteSpace: 'wrap', maxWidth: 654 }}
          >
            THE COMMUNITY LED DEFI ECOSYSTEM ON KADENA
          </Label>
        }
        imageUrl={coinImage}
        actions={
          <>
            <GradientContainer
              className="pointer"
              onClick={() => window.open('https://gov.ecko.finance', '_blank', 'noopener,noreferrer')}
              style={{ height: 38, padding: 0, width: 180 }}
            >
              <Label size="tiny" fontFamily="syncopate">
                Portal
              </Label>
            </GradientContainer>

            <GradientContainer
              className="pointer"
              onClick={() => window.open('https://discussion.ecko.finance', '_blank', 'noopener,noreferrer')}
              style={{ height: 38, padding: 0, width: 180 }}
            >
              <Label size="tiny" fontFamily="syncopate">
                Forum
              </Label>
            </GradientContainer>
          </>
        }
      />
      <Features />
      <Treasury />
      <Roadmap />
      <Community />
    </FlexContainer>
  );
};

export default MainContainer;
