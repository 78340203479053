import React from 'react';
import twitter from '../../assets/images/community/twitter-big.png';
import discord from '../../assets/images/community/discord-big.png';
import telegram from '../../assets/images/community/telegram-big.png';
import { FlexContainer } from '../shared/Container';
import Label from '../shared/Label';
import styled from 'styled-components';
import CornerBorderContainer from '../shared/CornerBorderContainer';

const DividerContainer = styled.div`
  max-width: 500px;
  border-top: 1px solid #ffffff;
  margin: 32px 0px;
  opacity: 0.4;
  mix-blend-mode: overlay;
`;

const SocialMediaNumbersWrapper = styled(FlexContainer)`
  img {
    @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel - 1}px`}) {
      height: 88px;
    }
  }
`;

const SocialMediaNumbers = () => {
  return (
    <CornerBorderContainer>
      <SocialMediaNumbersWrapper className="w-100">
        <img src={twitter} alt="" style={{ alignSelf: 'center', paddingRight: 40 }} />
        <FlexContainer className="column">
          <Label fontFamily="syncopate" size="huge">
            ~53K
          </Label>
          <Label fontFamily="syncopate-regular" size="medium" style={{ marginBottom: 16 }}>
            FOLLOWERS
          </Label>
          <Label fontFamily="basier" size="nano" style={{ marginBottom: 4 }}>
            twitter.com/eckoDAO
          </Label>
          <Label fontFamily="basier" size="nano">
            twitter.com/eckoWALLET
          </Label>
        </FlexContainer>
      </SocialMediaNumbersWrapper>
      <DividerContainer />
      <SocialMediaNumbersWrapper>
        <img src={discord} alt="" style={{ alignSelf: 'center', paddingRight: 40 }} />
        <FlexContainer className="column">
          <Label fontFamily="syncopate" size="huge">
            ~11K
          </Label>
          <Label fontFamily="syncopate-regular" size="medium" style={{ marginBottom: 16 }}>
            MEMBERS
          </Label>
          <Label fontFamily="basier" size="nano" style={{ marginBottom: 4 }}>
            discord.gg/eckoDAO
          </Label>
        </FlexContainer>
      </SocialMediaNumbersWrapper>
      <DividerContainer />
      <SocialMediaNumbersWrapper>
        <img src={telegram} alt="" style={{ alignSelf: 'center', paddingRight: 40 }} />
        <FlexContainer className="column">
          <Label fontFamily="syncopate" size="huge">
            ~6K
          </Label>
          <Label fontFamily="syncopate-regular" size="medium" style={{ marginBottom: 16 }}>
            MEMBERS
          </Label>
          <Label fontFamily="basier" size="nano" style={{ marginBottom: 4 }}>
            eckoDAO Official Telegram
          </Label>
        </FlexContainer>
      </SocialMediaNumbersWrapper>
    </CornerBorderContainer>
  );
};

export default SocialMediaNumbers;
