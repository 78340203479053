import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../components/shared/Container';
import Label from '../components/shared/Label';

const PrivacyPolicyWrapper = styled(FlexContainer)`
  padding: 64px 180px;
  padding-bottom: 0;
  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel + 1}px`}) {
    padding: 16px;
  }
`;

const PrivacyPolicyContainer = () => {
  return (
    <PrivacyPolicyWrapper className="justify-ce align-ce column w-100 h-100 margin-auto">
      <FlexContainer className="column align-ce justify-ce margin-auto" gap={40} style={{ marginBottom: 72 }}>
        <Label fontSize={48} fontFamily="syncopate">
          eckoDAO Wallet Privacy Policy
        </Label>
      </FlexContainer>
    </PrivacyPolicyWrapper>
  );
};

export default PrivacyPolicyContainer;
