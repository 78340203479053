import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from './Container';

const DividerContainer = styled(FlexContainer)`
  max-width: ${({ width }) => (width ? `${width}` : '100%')};
  height: 2px;
  background-color: transparent;
  width: 66em;
  margin: 0 auto;
  background-image: linear-gradient(left, transparent 2%, #ffd300 20%, #ff00b8 80%, transparent 98%);
  background-image: -o-linear-gradient(left, transparent 2%, #ffd300 20%, #ff00b8 80%, transparent 98%);
  background-image: -moz-linear-gradient(left, transparent 2%, #ffd300 20%, #ff00b8 80%, transparent 98%);
  background-image: -webkit-linear-gradient(left, transparent 2%, #ffd300 20%, #ff00b8 80%, transparent 98%);
  background-image: -ms-linear-gradient(left, transparent 2%, #ffd300 20%, #ff00b8 80%, transparent 98%);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0.02, transparent),
    color-stop(0.2, #ffd300),
    color-stop(0.8, #ff00b8),
    color-stop(0.98, transparent)
  );
`;

const GradientDivider = ({ width, style }) => {
  return (
    <>
      <DividerContainer width={width} style={style} />
    </>
  );
};

export default GradientDivider;
