import React from 'react';
import { FlexContainer } from '../shared/Container';
import CardWithImage from '../shared/CardWithImage';
import learningHubImg from '../../assets/images/learning-hub/learning-hub-img.png';
import { LEARNING_HUB } from '../../router/routes';

const FAIR_GOVERNANCE = {
  title: 'LEARNING HUB',
  description: 'Build your knowledge with the best resources across Kadena. Deep dive into the core of the eckoDAO Ecosystem and grow with us.',
  image: learningHubImg,
  link_label: 'DISCOVER',
  href: LEARNING_HUB,
};

const LearningHubSection = () => {
  return (
    <FlexContainer
      desktopPixel={1025}
      desktopStyle={{ padding: '80px 174px 80px' }}
      tabletStyle={{ padding: '80px 48px 80px' }}
      mobileStyle={{ padding: '80px 24px' }}
    >
      <CardWithImage lhImg item={FAIR_GOVERNANCE} style={{ padding: '32px 64px' }} />
    </FlexContainer>
  );
};

export default LearningHubSection;
