export const FOOTER_ABOUT = {
  title: 'ABOUT',
  color: 'grey',
  items: [
    {
      text: 'Features',
      href: '/#features',
    },
    {
      text: 'Treasury',
      href: '/#treasury',
    },

    {
      text: 'Roadmap',
      href: '/#roadmap',
    },
    {
      text: 'Community',
      href: '/#community',
    },
    {
      text: 'Blog',
      href: 'https://blog.ecko.finance',
      target: '_blank',
    },
  ],
};
export const PROJECT = {
  title: 'PROJECT',
  color: 'grey',
  items: [
    {
      text: 'eckoDEX',
      href: 'https://dex.ecko.finance',
      target: '_blank',
    },
    {
      text: 'eckoWALLET',
      href: 'https://wallet.ecko.finance',
      target: '_blank',
    },
  ],
};
export const GOVERNANCE = {
  title: 'GOVERNANCE',
  color: 'grey',
  items: [
    {
      text: 'Portal',
      href: 'https://gov.ecko.finance',
      target: '_blank',
    },
    {
      text: 'Forum',
      href: 'https://discussion.ecko.finance',
      target: '_blank',
    },
    /* {
      text: 'Documentation',
      href: '/',
      target: '_blank',
    }, */
  ],
};
