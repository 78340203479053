import React, { useEffect, useState } from 'react';
import { ArrowIcon } from '../../assets';
import { FlexContainer } from '../shared/Container';
import Label from '../shared/Label';
import NewsLine from './NewsLine';

const WhatsNew = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://blog.ecko.finance/ghost/api/content/posts/?key=edf8b0a355cdeb5c574279968d`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return response.json();
      })
      .then((actualData) => {
        setData(actualData);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setData(null);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <FlexContainer>
      <FlexContainer className="column" mobileStyle={{ marginBottom: 80 }}>
        <Label fontFamily="syncopate" fontSize={32} style={{ marginBottom: 80 }}>
          What's new
        </Label>
        {loading && <Label>A moment please...</Label>}
        {error && <Label>{`There is a problem fetching the post data - ${error}`}</Label>}
        {data && data.posts.slice(0, 4).map((info, i) => <NewsLine key={i} info={info} />)}
        {data && (
          <Label
            className="text-start pointer"
            color="white"
            fontFamily="syncopate"
            style={{ marginTop: 80 }}
            mobileStyle={{ marginTop: 0 }}
            tabletStyle={{ marginTop: 0 }}
            onClick={() => {
              window.open('https://blog.ecko.finance', '_blank', 'noopener,noreferrer');
            }}
          >
            See More
            <ArrowIcon style={{ marginLeft: 8, transform: 'rotate(-90deg)' }} />
          </Label>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default WhatsNew;
