import React from 'react';
import FAQSection from '../components/leaning-hub/FAQSection';
import Hero from '../components/sections/Hero';
import { FlexContainer } from '../components/shared/Container';
import lhImage from '../assets/images/learning-hub/lh-first-section.png';
import Label from '../components/shared/Label';
import useWindowSize from '../hooks/useWindowSize';

const LearningHubContainer = () => {
  const [width] = useWindowSize();

  return (
    <FlexContainer className="column">
      <Hero
        title={
          <FlexContainer mobileStyle={{ marginTop: 60 }}>
            <Label fontFamily="syncopate" size="huge">
              LEARNING
            </Label>
            <Label customColor="#219BB0" fontFamily="syncopate" size="huge" style={{ marginLeft: 16 }}>
              HUB
            </Label>
          </FlexContainer>
        }
        subTitle={
          <>
            <Label
              fontFamily="syncopate-regular"
              size="big"
              className="justify-ce wrap"
              style={{ letterSpacing: '0', textAlign: 'start', whiteSpace: 'wrap', maxWidth: 730 }}
              tabletStyle={{ maxWidth: 600 }}
              mobileStyle={{ maxWidth: 380 }}
            >
              {width < 500 ? 'BUILD UP YOUR KNOWLEDGE AND DEEP DIVE INTO THE CORE OF ' : ' BUILD UP YOUR KNOWLEDGE AND DEEP DIVE INTO THE'}
            </Label>
            <Label
              fontFamily="syncopate-regular"
              size="big"
              className="justify-fs wrap"
              style={{ letterSpacing: '0', textAlign: 'start', whiteSpace: 'wrap', maxWidth: 730 }}
            >
              {width < 500 ? ' THE EC ' : 'CORE OF THE EC'}
              <Label fontFamily="syncopate-regular" size="big" color="pink">
                K
              </Label>
              O-SYSTEM.
            </Label>
          </>
        }
        imgUrl={lhImage}
      />
      <FAQSection />
    </FlexContainer>
  );
};

export default LearningHubContainer;
