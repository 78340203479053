import React from 'react';
import { FlexContainer } from '../shared/Container';
import coinImage from '../../assets/images/first-section/coin-first-section.png';
import useWindowSize from '../../hooks/useWindowSize';
import styled, { css } from 'styled-components';
import theme from '../../styles/theme';
import GradientDivider from '../shared/GradientDivider';

const BackgroundContainer = styled(FlexContainer)`
  padding-bottom: 102px;
  padding-top: 102px;
  height: 105vh;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.desktopPixel - 1}px`}) {
    padding-top: 96px;
  }
  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel - 1}px`}) {
    padding-top: 96px;
    padding-bottom: 0px;
    height: 90vh;
  }
  z-index: 1;
`;
const BackgroundContainer2 = styled(FlexContainer)`
  background-image: ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : `url(${coinImage})`)};
  background-repeat: no-repeat;
  min-height: 100vh;
  ${({ width, theme: { mediaQueries } }) => {
    if (width >= mediaQueries.desktopPixel)
      return css`
        background-position: top 60% right -40px;
        background-size: 50%;
      `;
    else if (width > 375 && width < 768)
      return css`
        background-position: top 70% right 0px;
        background-size: contain;
      `;
    else if (width <= 375)
      return css`
        background-position: top 77% right 0px;
        background-size: contain;
      `;
    else
      return css`
        background-position: top 60% right -140px;
        background-size: contain;
      `;
  }}

  z-index: 1;
`;

const Hero = ({ title, subTitle, actions, imgUrl }) => {
  const [width] = useWindowSize();

  return (
    <>
      <BackgroundContainer className="  w-100 column align-ce justify-ce"></BackgroundContainer>
      <BackgroundContainer2 imgUrl={imgUrl} className="absolute w-100 justify-ce" width={width}>
        <FlexContainer
          className="w-100 h-100"
          desktopClassName="justify-sb align-ce"
          mobileClassName="justify-ce align-fs"
          tabletClassName="justify-ce align-ce"
          desktopPixel={1025}
          style={{ padding: '80px 174px 70px', height: '110vh' }}
          mobileStyle={{ padding: '56px 24px' }}
          tabletStyle={{ padding: '56px 48px' }}
        >
          <FlexContainer className="column" mobileClassName="w-100" tabletClassName="w-100">
            {title}
            <GradientDivider
              width={width > theme.mediaQueries.mobilePixel && width < theme.mediaQueries.desktopPixel ? '100%' : '70%'}
              style={{ margin: '32px 0px', marginLeft: width > theme.mediaQueries.mobilePixel ? -174 : -24 }}
            />
            {subTitle}
            {actions && (
              <FlexContainer gap={24} style={{ marginTop: 33 }}>
                {actions}
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
      </BackgroundContainer2>
    </>
  );
};

export default Hero;
