import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { MinusIcon, PlusIcon } from '../../assets';
import { FlexContainer } from './Container';
import GradientDivider from './GradientDivider';

const AccordionWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: ${({ open }) => (!open ? 'hidden' : 'none')};
  cursor: pointer;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;

  svg {
    position: absolute;
    display: block;

    @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel + 1}px`}) {
      width: 24px;
      height: 24px;
    }
  }
  .plus {
    top: 0px;
    right: 0px;
  }
  .minus {
    top: 14px;
    right: 0px;

    @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel + 1}px`}) {
      top: 0px;
    }
  }
  .icon-default {
    transition: opacity 0.3s, transform 0.3s;
  }

  .icon-hover {
    transition: opacity 0.3s, transform 0.3s;
    transform: rotate(-180deg) scale(0.5);
    opacity: 0;
  }

  .content-display {
    transition: opacity 0.8s, max-height 0.3s ease-in;
    max-height: 100%;
  }
  .content-none {
    transition: opacity 0.2s, max-height 0.3s ease-out;
    max-height: 0;
    opacity: 0;
  }

  ${({ open }) => {
    if (open) {
      return css`
        .icon-hover {
          transform: rotate(0deg) scale(1);
          opacity: 1;
        }
        .icon-default {
          transform: rotate(180deg) scale(0.5);
          opacity: 0;
        }
      `;
    }
  }}
`;

const Accordion = ({ header, content }) => {
  const [openAccordion, setOpenAccordion] = useState(false);

  return (
    <AccordionWrapper maxHeight="100%" onClick={() => setOpenAccordion(!openAccordion)} open={openAccordion}>
      <PlusIcon className="icon-default plus" />
      <MinusIcon className="icon-hover minus" />
      <FlexContainer>{header}</FlexContainer>
      <FlexContainer className={openAccordion ? 'content-display' : 'content-none'}>{content}</FlexContainer>
      <GradientDivider style={{ marginTop: 48 }} />
    </AccordionWrapper>
  );
};

export default Accordion;
