import React from 'react';
import styled from 'styled-components';
import { getColor } from '../../styles/theme';
import { FlexContainer } from './Container';
import Label from './Label';

const FeatureCardOverlayContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: space-between;
  align-items: flex-start;
  background: transparent linear-gradient(331deg, #ffffff66 0%, #1c203a 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: overlay;
  border: 1px solid #ffffff66;
  /* top: -20px;
  left: -20px; */
  border-radius: 32px;
  opacity: 0.2;
`;

const STYCard = styled(FlexContainer)`
  width: 407px;
  height: 408px;
  position: relative;
  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.desktopPixel}px`}) {
    width: 100%;
    height: 356px;
  }
  opacity: 1;

  svg {
    path {
      fill: ${({ color }) => getColor(color)};
    }
  }
`;

const FeatureCard = ({ feature, className }) => {
  return (
    <STYCard className={` align-fs justify-fs ${className}`} color={feature.color}>
      <FeatureCardOverlayContainer></FeatureCardOverlayContainer>
      <FlexContainer
        style={{ padding: 32 }}
        mobileStyle={{ padding: '56px 24px' }}
        mobileClassName="column h-100 justify-sb"
        tabletClassName="column align-fs justify-fs"
        desktopClassName="column align-fs justify-fs"
      >
        <Label opacity={0.4} className="with-stroke" fontFamily="syncopate" fontSize={64}>
          {feature.number}
        </Label>
        <Label
          className="feature-title "
          color={feature.color}
          fontSize={24}
          mobilePixel={1200}
          desktopStyle={{ marginTop: 24, marginBottom: 48 }}
          tabletStyle={{ marginTop: 20, marginBottom: 36 }}
          mobileStyle={{ marginTop: 20, marginBottom: 36 }}
          fontFamily="syncopate"
        >
          {feature.title}
        </Label>
        <Label className="text-start" color="white" size="nano">
          {feature.description}
        </Label>
      </FlexContainer>
    </STYCard>
  );
};

export default FeatureCard;
