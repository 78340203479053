import React from 'react';
import CardWithImage from '../shared/CardWithImage';
import { FlexContainer } from '../shared/Container';
import ambassador from '../../assets/images/community/ambassador.png';
import workshop from '../../assets/images/community/workshop.png';
import WhatsNew from './WhatsNew';
import SocialMediaNumbers from './SocialMediaNumbers';
import useWindowSize from '../../hooks/useWindowSize';

const AMBASSADOR = {
  title: 'AMBASSADOR',
  description:
    'Are you ready to participate in our awareness campaigns? Then join our ambassador program today! Propose initiatives and content ideas that can ignite growth and expand the eckoDAO audience and engagement.',
  image: ambassador,
  coming_soon: 'COMING SOON',
};

const WORKSHOP = {
  title: 'WORKSHOP',
  description:
    'The Ecko workshop allows for projects to integrate in the community by creating and developing groundbreaking products for the ecosystem. Push the boundaries of innovation by building a new dAPP, from the metaverse to infrastructures and oracles.',
  image: workshop,
  coming_soon: 'COMING SOON',
};

const Community = () => {
  const [width] = useWindowSize();
  return (
    <FlexContainer
      id="community"
      className="column"
      desktopPixel={1025}
      desktopStyle={{ padding: '80px 174px 120px' }}
      tabletStyle={{ padding: '80px 48px 80px' }}
      mobileStyle={{ padding: '80px 24px' }}
    >
      <FlexContainer gap={10} desktopPixel={1500} tabletClassName="column" mobileClassName="column">
        <CardWithImage item={AMBASSADOR} style={{ width: width >= 1500 ? '50%' : '100%' }} />
        <CardWithImage item={WORKSHOP} style={{ width: width >= 1500 ? '50%' : '100%' }} />
      </FlexContainer>
      <FlexContainer
        className="w-100 justify-sb"
        style={{ marginTop: 80 }}
        desktopPixel={1025}
        desktopStyle={{ alignItems: 'flex-end', gap: 90 }}
        tabletStyle={{ alignItems: 'flex-end', gap: 90 }}
        mobileClassName="column"
      >
        <WhatsNew />
        <SocialMediaNumbers />
      </FlexContainer>
    </FlexContainer>
  );
};

export default Community;
