import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from './Container';

import cornerRightUp from '../../assets/images/shared/corners/corner_right_up.png';
import cornerLeftUp from '../../assets/images/shared/corners/corner_left_up.png';
import cornerLeftDown from '../../assets/images/shared/corners/corner_left_down.png';
import cornerRightDown from '../../assets/images/shared/corners/corner_right_down.png';

const CornerBorderWrapper = styled(FlexContainer)`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  align-self: flex-start;
  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel - 1}px`}) {
    padding: 25px 30px;
    align-self: center;
  }
  .corner {
    filter: opacity(0.2);
    -webkit-filter: opacity(0.2);
  }
`;

const CORNERS = [
  {
    top: 0,
    right: 0,
    imageUrl: cornerRightUp,
  },
  {
    top: 0,
    left: 0,
    imageUrl: cornerLeftUp,
  },
  {
    bottom: 0,
    left: 0,
    imageUrl: cornerLeftDown,
  },
  {
    bottom: 0,
    right: 0,
    imageUrl: cornerRightDown,
  },
];

const CornerBorderContainer = ({ bgColor, children }) => {
  return (
    <CornerBorderWrapper bgColor={bgColor}>
      {CORNERS.map((corner) => (
        <img className="corner" src={corner.imageUrl} style={{ position: 'absolute', ...corner }} alt="corner" />
      ))}
      {children}
    </CornerBorderWrapper>
  );
};

export default CornerBorderContainer;
