import React from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { FlexContainer } from '../shared/Container';
import Label from '../shared/Label';
import FeatureCard from '../shared/FeatureCard';
import { FEATURES } from '../../constants/features';
import styled from 'styled-components';

const DESKTOP_PIXEL = 1025;

const MobileFeaturesContainer = styled(FlexContainer)`
  gap: 10px;
  .w-50 {
    width: 49%;
    @media (max-width: ${({ theme: { mediaQueries } }) => `600px`}) {
      width: 100%;
    }
  }
`;

const FeaturesSection = () => {
  const [width] = useWindowSize();
  return (
    <FlexContainer
      id="features"
      className="column relative w-100 align-ce scroll-mt"
      gap={32}
      desktopPixel={DESKTOP_PIXEL}
      desktopStyle={{ padding: '0 174px' }}
      tabletStyle={{ padding: '0 97px' }}
      mobileStyle={{ padding: '0 24px' }}
    >
      {width >= DESKTOP_PIXEL ? (
        // DESKTOP VIEW

        <>
          <FlexContainer id="row-1" gap={10} desktopStyle={{ flexFlow: 'nowrap', justifyContent: 'space-between' }} className="w-100 ">
            <FlexContainer desktopStyle={{ maxWidth: '39%' }} mobileClassName="column">
              <FlexContainer className="column justify-ce" tabletClassName="column">
                <Label className="align-fs " fontFamily="syncopate" fontSize={32}>
                  features
                </Label>
                <Label
                  className="align-fs "
                  size="small"
                  desktopPixel={DESKTOP_PIXEL}
                  desktopStyle={{ marginTop: 16 }}
                  tabletStyle={{ marginTop: 35 }}
                  mobileStyle={{ marginTop: 35 }}
                >
                  Enables the empowerment of $KDX, collectively owned, Kadena blockchain-governed organization working towards a shared mission.
                </Label>
              </FlexContainer>
            </FlexContainer>
            {FEATURES.slice(0, 2).map((feature, i) => (
              <FeatureCard key={i} feature={feature} />
            ))}
          </FlexContainer>
          <FlexContainer id="row-2" gap={10} desktopStyle={{ flexFlow: 'nowrap', justifyContent: 'space-between' }} className="w-100 ">
            {FEATURES.slice(2, FEATURES.length).map((feature, i) => (
              <FeatureCard key={i} feature={feature} />
            ))}
            <FlexContainer
              desktopPixel={DESKTOP_PIXEL}
              desktopStyle={{ maxWidth: width / 2 - (width / 2) * 0.4 }}
              className="column justify-ce"
              tabletClassName="column"
            >
              <Label
                size="small"
                style={{ textAlign: 'end' }}
                desktopPixel={DESKTOP_PIXEL}
                desktopStyle={{ marginTop: 16 }}
                tabletStyle={{ marginTop: 35 }}
                mobileStyle={{ marginTop: 35 }}
              >
                A collective of builders, products, and mutually beneficial ecosystems governed by $KDX token holders.
              </Label>
            </FlexContainer>
          </FlexContainer>
        </>
      ) : (
        // MOBILE VIEW
        <MobileFeaturesContainer className="wrap">
          <FlexContainer style={{ alignSelf: 'center', marginBottom: 46 }} className="w-50 column">
            <FlexContainer className="column justify-ce">
              <Label className="align-fs" fontFamily="syncopate" fontSize={32}>
                features
              </Label>
              <Label
                className="align-fs "
                size="small"
                desktopPixel={DESKTOP_PIXEL}
                desktopStyle={{ marginTop: 16 }}
                tabletStyle={{ marginTop: 35 }}
                mobileStyle={{ marginTop: 35 }}
              >
                Enables the empowerment of $KDX, collectively owned, Kadena blockchain-governed organization working towards a shared mission.
              </Label>
            </FlexContainer>
          </FlexContainer>
          {FEATURES.map((feature, i) => (
            <FeatureCard key={i} className="w-50" feature={feature} />
          ))}
          <FlexContainer className="w-50 column justify-ce" style={{ marginTop: 46 }}>
            <Label
              size="small"
              style={{ textAlign: 'end' }}
              desktopPixel={DESKTOP_PIXEL}
              desktopStyle={{ marginTop: 16 }}
              tabletStyle={{ marginTop: 35 }}
              mobileStyle={{ marginTop: 35 }}
            >
              A collective of builders, products, and mutually beneficial ecosystems governed by $KDX token holders.
            </Label>
          </FlexContainer>
        </MobileFeaturesContainer>
      )}
    </FlexContainer>
  );
};

export default FeaturesSection;
