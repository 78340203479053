export const EMPOWERING = {
  number: '01',
  title: 'EMPOWERING $KDX',
  description: 'Empowering $KDX holders through full governance control over the protocol’s development and mission.',
};
export const FAIR_GOVERNANCE = {
  number: '02',
  title: 'FAIR GOVERNANCE',
  description:
    'Using research from other industry leaders, and DAO applications, we have created a governance system that takes the best parts of other projects, while counteracting the negatives.',
};

export const FREE_VOTING = {
  number: '03',
  title: 'FREE ON-CHAIN VOTING',
  description: 'Kadena’s unique Gas Stations will allow DAO members to vote on on-chain proposals without having to ever pay for gas fees.',
};
export const COMMUNITY_DRIVEN = {
  number: '04',
  title: 'COMMUNITY DRIVEN',
  description:
    'Community proposals and fair voting allow token holders to have oversight and control over all crucial decisions, establishing the protocol and roadmap.',
};

export const FEATURES = [EMPOWERING, FAIR_GOVERNANCE, FREE_VOTING, COMMUNITY_DRIVEN];
