import React from 'react';
import { LEARNING_ECKODAO, LEARNING_ECKODEX, LEARNING_ECKOWALLET, LEARNING_INTRO } from '../../constants/learning-hub-faq';
import LHTemplate from '../shared/LHTemplate';

const FAQSection = () => {
  return (
    <>
      <LHTemplate
        title="INTRODUCTION"
        description="The world of DeFi has proven to be the first tangible and concrete blockchain use case. Various projects have developed a vast and revolutionary ecosystem of technologies that has shaken the world of Traditional Finance."
        items={LEARNING_INTRO}
      />
      <LHTemplate
        title="ECKODAO"
        description="eckoDAO exists to deliver the true vision of decentralized finance by creating end-to-end financial tools that are user-focused, accessible to all, scalable and fully sustainable."
        items={LEARNING_ECKODAO}
      />
      <LHTemplate
        title="ECKODEX"
        description="The revolutionary decentralized exchange that is set to transform the world of crypto trading. Powered by PACT, eckoDEX is a game-changing platform that will bring unmatched levels of security, speed, and efficiency to your trading experience."
        items={LEARNING_ECKODEX}
      />
      <LHTemplate
        title="ECKOWALLET"
        description="A state-of-the-art digital wallet that offers the ultimate user experience for managing digital assets on the Kadena blockchain. Our wallet is designed with the highest level of security in mind, so you can trust that your assets are always protected."
        items={LEARNING_ECKOWALLET}
      />
    </>
  );
};

export default FAQSection;
