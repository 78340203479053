import React from 'react';
import styled from 'styled-components';
import LearningHubSection from '../leaning-hub/LearningHubSection';
import { FlexContainer } from '../shared/Container';
import FeaturesSection from './FeaturesSection';
import bgFeatures from '../../assets/images/backgrounds/background-features.png';

const Wrapper = styled(FlexContainer)`
  flex-direction: column;
  background: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: -40px;
  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel - 1}px`}) {
    background-size: 268%;
  }
`;
const FeaturesWrapper = styled(FlexContainer)`
  width: 100%;

  padding-bottom: 90px;

  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel - 1}px`}) {
    padding: 80px 0px;
  }
`;

const Features = () => {
  return (
    <Wrapper>
      <LearningHubSection />
      <FeaturesWrapper gap={50} id="features" desktopStyle={{ maxHeight: 1280 }} mobileClassName="column" tabletClassName="column">
        <FeaturesSection />
      </FeaturesWrapper>
    </Wrapper>
  );
};

export default Features;
