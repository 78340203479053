import React from 'react';
import styled from 'styled-components';
import { ArrowIcon } from '../../assets';
import useWindowSize from '../../hooks/useWindowSize';
import { getColor } from '../../styles/theme';
import { FlexContainer } from './Container';
import Label from './Label';

const FeatureCardOverlayContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: space-between;
  align-items: flex-start;
  top: 0;
  left: 0;
  background: transparent linear-gradient(331deg, #ffffff66 0%, #1c203a 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: overlay;
  border: 1px solid #ffffff66;
  border-radius: 32px;
  opacity: 0.2;

  @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel - 1}px`}) {
    background: transparent linear-gradient(331deg, #ffffff66 0%, #1c203a 100%) 0% 0% no-repeat padding-box;
  }
`;

const STYCard = styled(FlexContainer)`
  width: 100%;
  position: relative;
  opacity: 1;

  svg {
    path {
      fill: ${({ color }) => getColor(color)};
    }
  }

  img {
    @media (max-width: ${({ theme: { mediaQueries } }) => `${mediaQueries.mobilePixel - 1}px`}) {
      width: 90%;
    }
  }
`;

const CardWithImage = ({ item, style, className, lhImg }) => {
  const [width] = useWindowSize();
  return (
    <STYCard
      className={`align-fs justify-sb ${className}`}
      color={item.color}
      style={{ padding: '24px', flexDirection: width <= 620 && 'column-reverse', ...style }}
      mobileStyle={{ padding: 0 }}
    >
      <FeatureCardOverlayContainer id="overlay" />
      <FlexContainer
        className="column justify-sb h-100"
        tabletStyle={{ alignSelf: 'center', padding: lhImg ? '0px' : '40px 0px 40px 24px' }}
        mobileStyle={{ alignSelf: 'center', padding: lhImg ? '40px 24px 40px 24px' : '40px 0px 40px 24px' }}
        desktopStyle={{ padding: lhImg ? '0px' : '40px 0px 40px 24px' }}
      >
        <Label
          className="feature-title"
          color={item.color}
          fontSize={24}
          desktopStyle={{ marginBottom: 40 }}
          tabletStyle={{ marginBottom: 25 }}
          mobileStyle={{ marginBottom: 25 }}
          fontFamily="syncopate"
        >
          {item.title}
        </Label>
        <Label
          className="text-start"
          color="white"
          size="nano"
          style={{ maxWidth: item.coming_soon ? 280 : 410 }}
          desktopStyle={{ marginBottom: 60 }}
          tabletStyle={{ marginBottom: 40 }}
          mobileStyle={{ marginBottom: 40 }}
        >
          {item.description}
        </Label>
        {item.link_label ? (
          <Label
            className="text-start pointer"
            color="white"
            fontFamily="syncopate"
            onClick={() => {
              window.open(item.href, '_blank', 'noopener,noreferrer');
            }}
          >
            {item.link_label}
            <ArrowIcon style={{ marginLeft: 8, transform: 'rotate(-90deg)' }} />
          </Label>
        ) : (
          ''
        )}
        {item.coming_soon ? (
          <Label className="text-end" opacity={0.2} color="white" fontFamily="syncopate">
            {item.coming_soon}
          </Label>
        ) : (
          ''
        )}
      </FlexContainer>
      <FlexContainer
        mobileClassName="justify-ce"
        style={{ alignSelf: 'center', justifyContent: 'end', paddingTop: width <= 888 && 32, marginTop: lhImg && '-88px' }}
        mobileStyle={{ justifyContent: 'center', marginTop: lhImg && '-68px' }}
      >
        <img src={item.image} alt="" style={{ width: lhImg ? '75%' : '92%' }} />
      </FlexContainer>
    </STYCard>
  );
};

export default CardWithImage;
