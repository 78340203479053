import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../shared/Container';
import Label from '../shared/Label';

const DividerContainer = styled.div`
  max-width: 685px;
  border-top: 1px solid #ffffff;
  margin: 32px 0px;
  opacity: 0.4;
  mix-blend-mode: overlay;
`;

const NewsLine = ({ info }) => {
  return (
    <FlexContainer
      className="column pointer"
      onClick={() => {
        window.open(info?.url, '_blank', 'noopener,noreferrer');
      }}
    >
      <FlexContainer style={{ marginBottom: 8 }}>
        <Label color="pink" fontFamily="syncopate">
          {moment.utc(info?.published_at).format('MMM D, YYYY')}
        </Label>
        {/*  <Label fontFamily="syncopate-regular" style={{ marginLeft: 16 }}>
          {info?.categories[0]}
        </Label> */}
      </FlexContainer>
      <Label fontFamily="syncopate" size="normal" style={{ maxWidth: '685px' }}>
        {info.title}
      </Label>
      <DividerContainer />
    </FlexContainer>
  );
};

export default NewsLine;
