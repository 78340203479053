import Roadmap2023 from '../components/roadmap/desktop-roadmaps/Roadmap2023';
import MobileRoadmap2023 from '../components/roadmap/mobile-roadmaps/MobileRoadmap2023';

export const R_2023 = {
  desktopImage: <Roadmap2023 />,
  mobileImage: <MobileRoadmap2023 />,
  id: '2023',
  color: 'light-yellow',
};

export const ROADMAPS = [R_2023];
